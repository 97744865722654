import { readonly, reactive } from 'vue'

const state = reactive({
  brand: {
    part1: 'Maths',
    part2: 'Hour',
    part3: 'UK',
    caption: 'Maths tuition',
    levels: 'BSc. Engg. | A Levels | GCSE',
    email: 'MathsHourUK@gmail.com',
    domain: '@gmail.com',
    mail_to: 'mailto:MathsHourUK@gmail.com?subject=Regarding tuition...',
  },

  hero: {
    title: `
    Expert Maths Tuition for GCSE, A-Level (Y1/Y2), and Uni level
    `,
    subtitle: `
    Comprehensive Guidance to Excel in Maths, Tailored to Your Unique Learning Journey
    `,
  },

  courses: [
    {
      coursename: 'GCSE',
      subjects: [
        {
          title: 'GCSE',
          cover: 'gcse.jpg'
        }
      ]
    },
    {
      coursename: 'A-level (Y1 & Y2)',
      subjects: [
        {
          title: 'A-level (Y2)',
          cover: 'ay2.jpg'
        },
        {
          title: 'A-level (Y1 & AS)',
          cover: 'ay1.jpg'
        },
      ]
    },
    {
      coursename: 'BSc. Engineering',
      subjects: [
        {
          title: 'Engineering Maths',
          cover: 'stroud.jpg'
        },
        {
          title: 'Engineering Maths - Advanced',
          cover: 'stroud_adv.jpg'
        },
      ],
    }
  ],

  features: {
    name: 'Unlimited live online tuition',
    firstmonth: 'Free First month',
    nocontract: 'no contract',
    nocard: 'no card details',
    noadvance: 'no advance payment',
    fee: '£10 per month',
    unlimitedlive: 'unlimited live online classes',
    duration: '45-min per class',
  },

  tutor: {
    name: 'Leo Santosh',
    pg: 'Masters - City, University of London',
    about: `
    MathsHour provides expert Maths tuition for GCSE, AS Level, A Level, and University students. Our experienced tutor offers personalised lessons designed to strengthen understanding, boost grades, and build confidence in key mathematical topics. Whether you're preparing for exams or looking to master specific areas, MathsHour is dedicated to helping students achieve their academic goals.
    `,
  },

  fee: {
    bankname: 'NatWest',
    accname: 'Santosh Singh',
    accnum: '1010 0431',
    sortcode: '60-60-15',
    paypal: 'https://www.paypal.me/MathsHour',
  },

  timetable: [
    {
      name: 'GCSE Maths',
      level: 'GCSE',
      thumbnail: 'gcse.jpg',
      mon: '7am',
      tue: '7am',
      wed: '7am',
      thu: '7am',
      fri: '',
      sat: '',
      sun: '',
    },
    {
      name: 'AS-level Maths',
      level: 'Year 1',
      thumbnail: 'ay1.jpg',
      mon: '9pm',
      tue: '9pm',
      wed: '9pm',
      thu: '9pm',
      fri: '',
      sat: '',
      sun: '',
    },
    {
      name: 'A-level Maths',
      level: 'Year 2',
      thumbnail: 'ay2.jpg',
      mon: '8pm',
      tue: '8pm',
      wed: '8pm',
      thu: '8pm',
      fri: '',
      sat: '',
      sun: '',
    },
    {
      name: 'Engineering Maths',
      level: 'BSc. Engineering',
      thumbnail: 'stroud.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '',
      sat: '9am',
      sun: '9am',
    },
    {
      name: 'Advanced Engineering Maths',
      level: 'BSc. Engineering',
      thumbnail: 'stroud_adv.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '',
      sat: '10am',
      sun: '10am',
    }
  ],
})

const methods = {
  onLoad() {
    console.log('courses loaded')
  },
}

const getters = {

}

export default {
  state: readonly(state),
  methods,
  getters,
}